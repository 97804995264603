<template>
  <v-card>
    <v-card-text>
      <h2 class="text-center">
        Báo cáo phân tích
      </h2>
    </v-card-text>
    <!-- height="378" -->
    <v-data-table
      :headers="
        headers"
      :items="tableData"
      :loading="loading"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [],
      }"
    >
      <template #item.title="{ item, index }">
        <a
          v-if="item.url"
          style="text-decoration:none"
          :href="item.url || ''"
          target="_blank"
        >
          {{ item.title }}
        </a>
        <template v-else>
          <Unlock
            :item="item"
            :index="index"
            @unlocked="unlock"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover
                v-slot="{ hover }"
              >
                <v-icon
                  small
                  v-bind="attrs"
                  :color="hover ? 'warning' : ''"
                  v-on="on"
                >
                  {{ hover ? 'mdi-lock-open' : 'mdi-lock' }}
                </v-icon>
              </v-hover>
            </template>
          </Unlock>
          <span class="pl-1">{{ item.title }} </span>
        </template>
      </template>
      <template #item.symbols="{ item }">
        <v-tooltip
          v-if="item.symbolsCount > 4"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="symbols"
              v-on="on"
            >
              {{ item.symbols }}
            </div>
          </template>
          <span>{{ item.symbols }}</span>
        </v-tooltip>
        <div
          v-else
          class="symbols"
        >
          {{ item.symbols }}
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import dayjs from 'dayjs'
import Unlock from '@/components/ArticleUnlock.vue'

export default {
  components: {
    Unlock,
  },

  props: {
    symbol: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: null,
      loading: false,
      headers: [
        {
          text: 'Tiêu đề',
          value: 'title',
        },
        {
          text: 'Mã CK',
          align: 'start',
          value: 'symbols',
          width: 155,
        },
        {
          text: 'Ngày đăng',
          value: 'created_at',
        },
      ],
    }
  },

  computed: {
    tableData() {
      if (this.items == null) return []
      this.items.forEach(item => {
        item.symbolsCount = item.symbols.length
        item.symbols = item.symbols.join(', ')
        item.created_at = dayjs(item.created_at).format('DD/MM/YYYY')
      })

      return this.items
    },
  },

  watch: {
    symbol() {
      this.fetch()
    },
  },

  created() {
    this.fetch()
  },

  methods: {
    fetch() {
      this.loading = true
      this.$httpUser.get(`/api/companies/${this.symbol}/articles`)
        .then(response => {
          const { data } = response.data
          this.items = data
        })
        .catch(() => {
          this.items = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    unlock(index, data) {
      const article = this.items[index]
      article.url = data.attachment.url
      this.$set(this.items, index, article)
    },
  },
}
</script>

<style scoped>
.symbols {
  width: 155px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
