<template>
  <v-card
    :loading="loading"
  >
    <v-card-text>
      <h2 class="text-center">
        {{ title }}
      </h2>
    </v-card-text>
    <v-simple-table
      :class="{ 'pt-1': loading }"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(h, i) in header"
              :key="i"
            >
              <h3
                class="font-weight-semibold"
                :class="{'text-center': i == 0, 'text-right': i > 0 }"
              >
                {{ h }}
              </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="key"
          >
            <td class="text-center">
              <span class="font-weight-semibold d-inline-block my-1">
                {{ $t(key) }}
              </span>
              <br>
              <span class="text-subtitle-2 d-inline-block mb-1">
                {{ key==='plan' ? $t('achieved') : '&nbsp;' }}
              </span>
            </td>
            <td
              v-for="(d, i) in item"
              :key="i"
              class="py-0 px-2 text-right"
            >
              <span
                class="font-weight-semibold d-inline-block my-1"
              >
                {{ d.value ? (new Intl.NumberFormat('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(d.value)) : '---' }}
              </span>
              <br>
              <span v-if="d.growth">
                <v-icon
                  small
                  :color="d.growth > 0 ? 'info' : 'error'"
                  class="d-inline-block mb-1"
                >
                  mdi-arrow-{{ d.growth > 0 ? 'up' : 'down' }}
                </v-icon>
                <span
                  :class="(d.growth > 0 ? 'info' : 'error') +'--text'"
                  class="text-subtitle-2 d-inline-block"
                >
                  {{ (d.growth * 100).toFixed(1) }}%
                </span>
              </span>
              <span v-else-if="d.achieved">
                <span class="text-subtitle-2 d-inline-block pb-1">
                  {{ (d.achieved * 100).toFixed(1) }}%
                </span>
              </span>
              <span v-else>---</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {

  props: {
    symbol: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    apiRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: null,
      loading: false,
    }
  },

  computed: {
    header() {
      let header = ['Thời kỳ']
      for (let i = 3; i >= 0; i -= 1) {
        header = [...header, this.year - i]
      }

      return header
    },
  },

  watch: {
    symbol() {
      this.fetch()
    },
  },

  created() {
    this.fetch()
  },

  methods: {
    fetch() {
      // this.loading = true
      const url = `/api/companies/${this.symbol}/${this.apiRef}`
      const params = {
        year: this.year,
        unit: 'billion',
      }
      this.$httpUser.get(url, { params })
        .then(response => {
          const { data } = response.data
          this.items = data
        })
        .catch(() => {
          this.items = []
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
