var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('h2',{staticClass:"text-center"},[_vm._v(" Báo cáo phân tích ")])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"loading":_vm.loading,"items-per-page":10,"footer-props":{
      'items-per-page-options': [],
    }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [(item.url)?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.url || '',"target":"_blank"}},[_vm._v(" "+_vm._s(item.title)+" ")]):[_c('Unlock',{attrs:{"item":item,"index":index},on:{"unlocked":_vm.unlock},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":hover ? 'warning' : ''}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(hover ? 'mdi-lock-open' : 'mdi-lock')+" ")])]}}],null,true)})]}}],null,true)}),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(item.title)+" ")])]]}},{key:"item.symbols",fn:function(ref){
    var item = ref.item;
return [(item.symbolsCount > 4)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"symbols"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.symbols)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.symbols))])]):_c('div',{staticClass:"symbols"},[_vm._v(" "+_vm._s(item.symbols)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }