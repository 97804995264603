<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card v-if="companyInfo">
        <v-card-title>
          {{ companyInfo.name }} ({{ companyInfo.exchange }}: {{ companyInfo.symbol }})
        </v-card-title>
        <v-card-text>
          <b>Ngành:</b> {{ companyInfo.industry }}
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-skeleton-loader
          type="list-item@2"
          width="50%"
        ></v-skeleton-loader>
      </v-card>
    </v-col>
    <!-- <v-col
      cols="12"
      sm="6"
    >
      <v-card>
        <v-card-text
          class="d-flex flex-column justify-center"
          style="height: 240px"
        >
          <template v-if="Object.keys(tradingInfo).length > 0">
            <div class="d-flex align-center justify-center">
              <h1 class="text-4xl font-weight-semibold">
                {{ tradingInfo.last_price }}
              </h1>

              <div
                v-if="tradingInfo.change != 0"
                class="d-flex align-center"
              >
                <v-icon
                  size="30"
                  :color="priceColor"
                >
                  mdi-arrow-{{ tradingInfo.change > 0 ? 'up' : 'down' }}-bold
                </v-icon>
              </div>
            </div>
            <div class="d-flex flex-column align-center justify-center">
              <h4 class="mt-2 font-weight-medium">
                <span :class="`${priceColor}--text`">{{ tradingInfo.change }} ({{ tradingInfo.percentage_change }} %)</span>
              </h4>
              <h4 class="mt-2 font-weight-medium">
                {{ tradingDate }}
              </h4>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-center justify-center">
              <v-icon x-large>
                mdi-database-remove-outline
              </v-icon>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col> -->
    <v-col cols="12">
      <ArticleTable
        :symbol="symbol"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <StatisticTable
        v-if="year"
        :symbol="symbol"
        title="Doanh Thu"
        :year="year"
        api-ref="revenue"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <StatisticTable
        v-if="year"
        :symbol="symbol"
        title="Lợi Nhuận"
        :year="year"
        api-ref="profit"
      />
    </v-col>
  </v-row>
</template>

<script>

import StatisticTable from '../components/StatisticTable.vue'
import ArticleTable from '../components/ArticleTable.vue'
import dateTime from '@/common/mixins/date-time'

export default {
  components: {
    StatisticTable,
    ArticleTable,
  },

  mixins: [dateTime],

  data() {
    return {
      tradingInfo: {},
    }
  },

  computed: {

    symbol() {
      return this.$route.params.symbol
    },

    companyInfo() {
      return this.$store.getters['company/getBySymbol'](this.symbol)
    },

    tradingDate() {
      return this.formatDate(this.tradingInfo.trading_date, 'DD/MM/YYYY HH:mm')
    },

    year() {
      const delay = 1
      const date = new Date()
      let year = date.getFullYear()
      const quarter = Math.ceil((date.getMonth() + 1) / 3)
      if (quarter - delay <= 0) year -= 1

      return year
    },

    priceColor() {
      switch (true) {
        case this.tradingInfo.change > 0:
          return 'success'
        case this.tradingInfo.change < 0:
          return 'error'
        default:
          return 'warning'
      }
    },

    column1() {
      return [
        {
          label: 'Mở cửa',
          value: this.tradingInfo.open_price,
          color: 'warning',
        },
        {
          label: 'Cao nhất',
          value: this.tradingInfo.highest_price,
          color: 'success',
        },
        {
          label: 'Thấp nhất',
          value: this.tradingInfo.lowest_price,
          color: 'error',
        },
        {
          label: 'KLGD',
          value: this.tradingInfo.total_volume,
        },
        {
          label: 'Vốn hóa',
          value: this.tradingInfo.market_capital
            ? this.$_.round((this.tradingInfo.market_capital / 1000000000), 2).toFixed(2)
            : null,
        },
      ]
    },

    column2() {
      return [
        {
          label: 'Dư mua',
          value: this.tradingInfo.outstanding_buy,
        },
        {
          label: 'Dư bán',
          value: this.tradingInfo.outstanding_sell,
        },
        {
          label: 'Cao 52T',
          value: this.tradingInfo.max_52_weeks,
        },
        {
          label: 'Thấp 52T',
          value: this.tradingInfo.min_52_weeks,
        },
        {
          label: 'KLBQ 52T',
          value: this.tradingInfo.volume_52_weeks,
        },
      ]
    },

    column3() {
      return [
        {
          label: 'NN mua',
          value: this.tradingInfo.f_buy_volume,
        },
        {
          label: '% NN sở hữu',
          value: this.tradingInfo.owned_ratio,
        },
        {
          label: 'Cổ tức TM',
          value: this.tradingInfo.dividend,
        },
        {
          label: 'T/S cổ tức',
          value: this.tradingInfo.yield,
        },
        {
          label: 'Beta',
          value: this.tradingInfo.beta,
        },
      ]
    },

    column4() {
      return [
        {
          label: 'EPS',
          value: this.tradingInfo.eps,
        },
        {
          label: 'P/E',
          value: this.tradingInfo.pe,
        },
        {
          label: 'F P/E',
          value: this.tradingInfo.feps,
        },
        {
          label: 'BVPS',
          value: this.tradingInfo.bvps,
        },
        {
          label: 'P/B',
          value: this.tradingInfo.pb,
        },
      ]
    },
  },

  watch: {
    symbol() {
      this.fetchTradingInfo()
    },

    companyInfo(value) {
      if (value === false) {
        this.$router.push({ name: 'error-404' })
      }
    },
  },

  created() {
    this.fetchTradingInfo()
  },

  methods: {
    fetchTradingInfo() {
      const url = `/api/companies/${this.symbol}/tradinginfo`
      this.$httpUser.get(url)
        .then(response => {
          const { data } = response.data
          this.tradingInfo = data
        })
        .catch(() => {
          this.tradingInfo = {}
        })
    },
  },
}
</script>
